<template>
  <div>
    <v-data-table
      dense
      item-key="_id"
      class="transparent mt-2"
      :items="productionColumns"
      :headers="headers"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template #loading>
        Loading
      </template>
      <template #item.display="{ item, index }">
        <v-switch
          hide-details
          v-model="item.display"
          @change="ignoreColumn(item, index)"
          label="Show/Hide"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'ColumnConfig',
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t('production.column'),
          value: 'text',
          width: '60%',
        },
        {
          text: this.$t('production.display'),
          value: 'display',
        },
      ],
    };
  },
  computed: {
    ...mapState('webApp', ['appSchema']),
    productionColumns() {
      let columns = [];
      if (this.appSchema && this.appSchema.productionHeaders
        && this.appSchema.productionHeaders.length) {
        columns = [...this.appSchema.productionHeaders];
      }
      return columns;
    },
  },
  methods: {
    ...mapMutations('productionLog', ['setLoading']),
    ...mapActions('webApp', ['getAppSchema', 'updateAppSchema']),
    async ignoreColumn(item, index) {
      const updatedSchema = [...this.appSchema.productionHeaders];
      updatedSchema[index] = item;
      const payload = {
        productionHeaders: updatedSchema,
      };
      this.setLoading(true);
      await this.updateAppSchema({ appSchema: payload, saveForAll: false });
      await this.getAppSchema();
      this.setLoading(false);
    },
  },
};
</script>
