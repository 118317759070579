var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('on-update', {
    id: _vm.item._id,
    hour: _vm.hour,
    payload: {
      quantity: _vm.item.quantity,
      reasonname: _vm.item.reasonname,
      remark: _vm.item.remark,
      editedBy: _vm.item.editedBy,
    },
  })}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card',[_c('v-card-title',{staticClass:"title font-weight-regular justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('production.editRejection'))+" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.item)?_c('v-card-text',{staticClass:"py-0"},[_c('v-text-field',{staticClass:"mt-1",attrs:{"type":"number","label":_vm.assetType === 'kgs' ? _vm.$t('production.weight')
            : _vm.$t('production.quantity'),"outlined":"","dense":"","suffix":_vm.assetType === 'kgs'
            ? _vm.$t('production.kilograms') : _vm.$t('production.pieces'),"rules":[(v) => (
            (v > 0 &&
            Number.isInteger(Number(v)) > 0
            && parseInt(v, 10) <= _vm.acceptedQty)
            || (this.isAdmin && v >= 0 && Number.isInteger(Number(v)) >= 0
            && parseInt(v, 10) <= _vm.acceptedQty)
          )
            || _vm.$t('production.validations.quantity')]},model:{value:(_vm.item.quantity),callback:function ($$v) {_vm.$set(_vm.item, "quantity", _vm._n($$v))},expression:"item.quantity"}}),_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":_vm.$t('production.reason'),"disabled":_vm.updating,"item-text":"reasonname","item-value":"reasonname","items":_vm.rejectionReasons,"rules":[(v) => !!v || _vm.$t('production.validations.reasonRequired')]},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.reasoncode)+" | "+_vm._s(data.item.reasonname)+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.reasonname)+" ")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(data.item.reasoncode)}})],1)]}}],null,false,3959389885),model:{value:(_vm.item.reasonname),callback:function ($$v) {_vm.$set(_vm.item, "reasonname", $$v)},expression:"item.reasonname"}}),_c('v-textarea',{attrs:{"label":_vm.$t('production.remark'),"rows":"2","outlined":"","dense":""},model:{value:(_vm.item.remark),callback:function ($$v) {_vm.$set(_vm.item, "remark", $$v)},expression:"item.remark"}})],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{staticClass:"text-none",attrs:{"block":"","type":"submit","color":"primary","disabled":!_vm.isValid,"loading":_vm.updating}},[_vm._v(" "+_vm._s(_vm.$t('production.updateRejection'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }